<template>
    <section>
        <tabs class="border-bottom" :tabs="rutas" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 122px);">
            <router-view :key="$route.name" />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                {
                    titulo: '',
                    ruta: 'admin.cupones.clientes.lista',
                    can: 'tab1_cupones_clientes'
                },
                {
                    titulo: '',
                    ruta: 'admin.cupones.clientes.dashboard',
                    can:'tab1_cupones_clientes'
                },
                {
                    titulo: '',
                    ruta: 'admin.cupones.lechero.lista',
                    can: 'tab1_cupones_leecheros'
                },
                {
                    titulo: '',
                    ruta: 'admin.cupones.lechero.dashboard',
                    can: 'tab1_cupones_leecheros'
                }
            ]
        }
    },
    mounted(){
        this.rutas[0].titulo = `Lista de cupones ${this.$config.cliente}`
        this.rutas[1].titulo = `Estadisticas ${this.$config.cliente}`
        this.rutas[2].titulo = `Lista de cupones ${this.$config.vendedor}`
        this.rutas[3].titulo = `Estadisticas ${this.$config.vendedor}`
    }
    
}
</script>
